<template>
  <SectionBasic
    id="outro"
    :isActive="isActive">
    <h2 class="tit_section">온 가족이 함께 똑똑한<br>용돈관리를 시작해보세요</h2>
    <div class="area_img">
      <div class="inner_img"></div>
    </div>
  </SectionBasic>
</template>

<script>
import SectionBasic from '@/components/common/section/SectionBasic.vue';

export default {
  name: 'SectionOutro',
  props:{
    isActive: Boolean
  },
  components:{
    SectionBasic,
  },
}
</script>

<style scoped>
.section_outro{background-color:#fff}
.section_outro .tit_section{font-weight:700;font-size:24px;line-height:36px;color:#111;text-align:center}
.section_outro .area_img{max-width:640px;margin:24px auto 0}
.section_outro .area_img .inner_img{padding-top:66.6%;background:url(/assets/images/card/img_outro.jpg) no-repeat 0 0;background-size:100%;}
</style>