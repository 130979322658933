<template>
  <SectionBasic
    id="intro"
    :isActive="isActive">
    <h2 class="tit_section">벌써부터 카드<br>만들어줘도 괜찮을까?</h2>
    <p class="desc_section">안심하세요<br>부모님이 현금보다 <span class="txt_break_response txt_point">유연하게 통제 가능해</span><br>편리하고 안전하게 관리할 수 있어요</p>
    <div class="box_intro">
      <div class="area_img area_img1">
        <div class="inner_img"></div>
      </div>
      <div class="body_box">
        <strong class="tit_intro">서류 없이 앱으로<br>간편하게 무료 발급</strong>
        <p class="desc_intro">부모 · 자녀 본인 인증 필수</p>
      </div>
    </div>
    <div class="box_intro">
      <div class="area_img area_img2">
        <div class="inner_img"></div>
      </div>
      <div class="body_box body_box_border">
        <strong class="tit_intro">자녀의 결제내역 및 잔액<br>실시간 확인</strong>
      </div>
    </div>
  </SectionBasic>
</template>

<script>
import SectionBasic from '@/components/common/section/SectionBasic.vue';

export default {
  name: 'SectionIntro',
  props:{
    isActive: Boolean
  },
  components:{
    SectionBasic,
  },
}
</script>

<style scoped>
.section_intro{background-color:#F3F1F1}
.section_intro .desc_section .txt_point{display:inline-block;position:relative;font-weight:700;vertical-align:top}
.section_intro .desc_section .txt_point:after{position:absolute;bottom:-1px;left:-1px;right:-1px;height:2px;border-radius:2px;background-color:#8F69DD;content:''}

.section_intro .box_intro{overflow:hidden;max-width:640px;margin:24px auto 0;border-radius:8px;background-color:#8F69DD}

.section_intro .area_img{width:100%;max-width:320px;margin:0 auto}
.section_intro .area_img1 .inner_img{padding-top:64.375%;background-image:url(/assets/images/card/img_intro1.jpg)}
.section_intro .area_img2 .inner_img{padding-top:74.375%;background-image:url(/assets/images/card/img_intro2.jpg)}
.section_intro .area_img .inner_img{background-repeat:no-repeat;background-size:contain}

.section_intro .body_box_border{border-top:1px solid #E7E5E5}

.section_intro .body_box{padding:16px 32px;background-color:#fff;text-align:center}
.section_intro .tit_intro{display:block;font-weight:700;font-size:20px;line-height:28px;color:#111}
.section_intro .desc_intro{margin-top:8px;font-weight:500;font-size:14px;line-height:22px;color:#8F69DD}
</style>