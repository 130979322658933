<template>
  <SectionBasic
    id="motivate"
    :isActive="isActive">
    <h2 class="tit_section">퍼핀월드에서 경제금융학습을<br>재미있게 할 수 있어요</h2>
    <p class="desc_section">실생활에 꼭 필요한 기초상식,<br>교과과정과 연계된 경제금융지식을<br>재미있는 퀴즈로 배워요</p>
    <div id="slideMoveEndPoint" class="group_slide" :style="{ '--slideLeft' : slideLeft }">
      <div class="inner_group_slide">
        <div class="item_motivate_slide">
          <div class="img_slide" style="background-image:url(/assets/images/card/img_motivate_slide1_230414.jpg"></div>
        </div>
        <div class="item_motivate_slide">
          <div class="img_slide" style="background-image:url(/assets/images/card/img_motivate_slide2_230414.jpg"></div>
        </div>
        <div class="item_motivate_slide">
          <div class="img_slide" style="background-image:url(/assets/images/card/img_motivate_slide3.jpg"></div>
        </div>
      </div>
    </div>
    <!-- <p class="desc_motivate">실생활에 꼭 필요한 경제금융상식과 시사 지식을 매일 5개의 퀴즈로 배울 수 있는 퍼핀월드!<br>1일 최대 2천원까지 학습 보상을 설정할 수 있어요.</p> -->
    <div id="slideMoveStartPoint" class="box_setting">
      <span class="icon_motivate"></span>
      <p class="desc_setting">하루 5문제, 최대 2천원까지<br>학습보상 설정 가능</p>
    </div>
    <div class="box_tip">
      <span class="badge_tip ir_pm">TIP</span>
      <p class="desc_tip">정기용돈은 조금 부족하게 주되 <span class="txt_break_response">학습 보상으로</span> 보너스 용돈을 더해주면 동기부여에 도움돼요.</p>
    </div>
  </SectionBasic>
</template>

<script>
import SectionBasic from '@/components/common/section/SectionBasic.vue';
import { mapGetters } from 'vuex';

export default {
  name: 'SectionMotivate',
  props:{
    isActive: Boolean,
    scrollTop: Number
  },
  components:{
    SectionBasic,
  },
  mounted(){
    this.setSlideMoveData();
  },
  computed:{
    ...mapGetters('commonViewport', [ 'getWindowWidth', 'getWindowHeight' ]),
  },
  watch:{
    scrollTop(){
      this.calcScrollPersent(this.scrollTop)
    },
    getWindowWidth(){
      this.calcScrollPersent(this.scrollTop)
    },
    getWindowHeight(){
      this.setSlideMoveData();
    }
  },
  data(){
    return{
      moveStartScroll:null, // 슬라이드가 시작하는 스크롤값
      moveEndScroll:null, // 슬라이드가 끝나는 스크롤값
      slideWidth: 620, // 슬라이드 총 너비
      slideStartLeft:0, // 슬라이드 시작 left (슬라이드 하나 너비의 반)
      // slideEndLeft:-312, // 슬라이드 끝 left
      slideLeft:'0px' // 실제 동작 슬라이드 left 값
    }
  },
  methods:{
    setSlideMoveData(){
      this.$nextTick(() => {
        const slideMoveStartPointTop = document.getElementById('slideMoveStartPoint').offsetTop - 40; // 슬라이드와 문구 사이 여백
        const slideMoveEndPointTop = document.getElementById('slideMoveEndPoint').offsetTop - 54; // 헤더 높이
        const footerHeight = 56 + 32; // 플로팅 버튼 높이 = 버튼높이 + 하단 여백
        this.moveStartScroll = slideMoveStartPointTop - ( this.getWindowHeight - footerHeight );
        this.moveEndScroll = slideMoveEndPointTop;
      });
    },
    calcScrollPersent(sct){
      if(this.getWindowWidth < this.slideWidth + 40){
        const totalScroll = this.moveEndScroll - this.moveStartScroll;
        const targetScroll = sct - this.moveStartScroll;
        let slidePersent = 0;
        if(targetScroll < 0){
          slidePersent = 0
        }else if(sct > this.moveEndScroll){
          slidePersent = 1
        }else{
          slidePersent = targetScroll / totalScroll
        }
        const slideEndLeft = this.getWindowWidth - this.slideWidth - 40;
        const totalSlide = slideEndLeft - this.slideStartLeft;
        this.slideLeft = this.slideStartLeft + (totalSlide * slidePersent) + 'px'
      }
    }
  }
}
</script>

<style scoped>
.section_motivate{overflow:hidden;background-color:#F1EEE1}
/* .section_motivate >>> .inner_section{padding:0} */
/* .section_motivate .tit_section{padding:0 20px} */
.section_motivate .group_slide{position:relative;padding:40px 0}
.section_motivate .group_slide .inner_group_slide{width:620px;margin:0 auto;text-align:center}
.section_motivate .item_motivate_slide{display:inline-block;width:180px;height:380px;border:8px solid #232323;border-radius:24px;background-color:#232323;box-shadow:0px 24px 24px rgba(0, 0, 0, 0.12);vertical-align:top}
.section_motivate .item_motivate_slide .img_slide{border-radius:18px;background-repeat:no-repeat;background-size:180px;height:380px}
.section_motivate .item_motivate_slide + .item_motivate_slide{margin-left:16px}

.section_motivate .box_setting{position:relative;max-width:620px;margin:0 auto;padding:20px 20px 20px 76px;border-radius:8px;background-color:#fff;box-sizing:border-box}
.section_motivate .box_setting .icon_motivate{position:absolute;top:22px;left:20px;width:40px;height:40px;background:url(/assets/images/card/img_motivate_f.png) no-repeat;background-size:contain}

.section_motivate .box_tip{position:relative;max-width:620px;margin:28px auto 0;padding:20px;border-radius:8px;background-color:#E2D7C4;box-sizing:border-box}
.section_motivate .box_tip .badge_tip{position:absolute;top:-12px;left:20px;width:49px;height:24px;background:url(/assets/images/card/img_motivate_tip.png) no-repeat;background-size:contain}
.section_motivate .box_tip .desc_tip{font-weight:500;font-size:16px;line-height:24px;color:#111}


/* 모바일 */
@media all and (max-width:659px){
  .section_motivate .group_slide{position:relative;left:var(--slideLeft);padding:40px 0;transition: left 0.2s ease}
  .section_motivate .group_slide .inner_group_slide{margin:0}
}
</style>