<template>
  <SectionBasic
    id="practice"
    :isActive="isActive">
    <h2 class="screen_out">소비습관</h2>
    <div class="box_practice">
      <strong class="tit_practice">아이 이름이 새겨진 카드로</strong>
      <p class="desc_practice">돈에 대한 책임감과 자립심을 키워주세요</p>
      <div class="area_img area_img1">
        <div class="inner_img"></div>
      </div>
    </div>
    <div class="box_practice">
      <strong class="tit_practice">편리한 용돈 관리를 넘어</strong>
      <p class="desc_practice">돈에 대한 <span class="txt_point">개념과 태도, 관리 방법을</span><br>배울 수 있도록 설계되었어요</p>
      <div class="area_img area_img2">
        <div class="inner_img"></div>
      </div>
      <ul class="list_practice">
        <li>자녀에게 실시간 결제내역과 잔액 알림</li>
        <li>계획적인 소비가 가능하도록 맞춤 메시지 발송</li>
      </ul>
      <swiper
        class="swiper_practice"
        :options="swiperpracticeOption"
        ref="practiceSwiper">
        <swiper-slide v-for="index of 10" :key="'practiceSwiper'+index">
          <div class="item_practice_slide">
            <div class="img_slide" :style="{ 'background-image':`url(/assets/images/card/img_category${index}.png)` }" ></div>
            <span class="txt_slide">{{ slideTextDataList[index-1] }}</span>
          </div>
        </swiper-slide>
      </swiper>
    </div>
  </SectionBasic>
</template>

<script>
import SectionBasic from '@/components/common/section/SectionBasic.vue';
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'

export default {
  name: 'SectionPractice',
  props:{
    isActive: Boolean
  },
  components:{
    SectionBasic,
    Swiper,
    SwiperSlide,
  },
  mounted(){
    this.$nextTick(() => {
      if(this.$refs.practiceSwiper && this.$refs.practiceSwiper.$swiper){
        this.isInit = true;
      }
    });
  },
  data(){
    return{
      slideTextDataList:[
        '먹는 데',
        '쇼핑하는 데',
        '노는 데',
        '운동하는 데',
        '병원가는 데',
        '꾸미는 데',
        '베푸는 데',
        '이동하는 데',
        '요금내는 데',
        '알수없는 데',
      ],
      isInit: false,
      swiperpracticeOption:{
        allowTouchMove:false,
        autoplay: {
          delay: 0,
        },
        loop: true,
        slidesPerView: "auto",
        spaceBetween: 12,
        freeMode: true,
        speed: 3000,
      }
    }
  },
}
</script>

<style scoped>
.section_practice{background-color:#000}

.section_practice .box_practice{max-width:640px;margin:0 auto;padding:24px 32px;border-radius:8px;background-color:#232323;text-align:center}
.section_practice .box_practice + .box_practice{margin-top:24px}

.section_practice .tit_practice{font-weight:700;font-size:20px;line-height:28px;color:#fff}
.section_practice .desc_practice{margin-top:4px;font-weight:500;font-size:16px;line-height:28px;color:#fff}

.section_practice .desc_practice .txt_point{display:inline-block;padding:0 8px;font-weight:700;border-radius:2px;background-color:#000;vertical-align:top}

.section_practice .list_practice{display:inline-block;margin:24px auto 0;padding-left:26px;text-align:left}
.section_practice .list_practice,
.section_practice .list_practice li{list-style:disc}
.section_practice .list_practice li{font-weight:500;font-size:14px;line-height:22px;color:#999}

.section_practice .area_img{width:100%;max-width:256px;margin:24px auto 0}
.section_practice .area_img1 .inner_img{padding-top:62.5%;background-image:url(/assets/images/card/img_practice1.png)}
.section_practice .area_img2 .inner_img{padding-top:78.13%;background-image:url(/assets/images/card/img_practice2.png)}
.section_practice .area_img .inner_img{background-repeat:no-repeat;background-size:contain}


.section_practice .swiper_practice{height:80px;margin:24px -32px 0}
.section_practice .swiper_practice >>> .swiper-wrapper{transition-timing-function : linear}
.section_practice .swiper_practice .swiper-slide{width:80px;height:80px;padding:13px 0;border-radius:4px;background-color:#000}
.section_practice .swiper_practice .img_slide{width:32px;height:32px;margin:0 auto;background-size:100%}
.section_practice .swiper_practice .txt_slide{display:block;margin-top:6px;font-weight:600;font-size:12px;line-height:16px;color:#fff}
</style>