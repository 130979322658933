
export default class CardRegisterViewModel {
  constructor() {
    this.isCardRegisterClosePopup = false;
  }
  onClickClose(){
    this.isCardRegisterClosePopup = true;
  }
  onClickCardRegisterClosePopupClose(){
    this.isCardRegisterClosePopup = false;
  }
}