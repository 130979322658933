<template>
  <div
    :class="['section_comm', `section_${id}`, { active : isActive }]"
    :id="'section'+id.charAt(0).toUpperCase() + id.slice(1)">
    <div :class="['inner_section', `inner_${id}`]">
      <slot/>
    </div>
  </div>
</template>
<script>
export default {
  name:'SectionBasic',
  props:{
    id: String,
    isActive: Boolean
  },
}
</script>
<style scoped>
/* 섹션 공통 */
.section_comm .inner_section{max-width:1280px;margin:50px auto -50px;padding:0 20px;opacity:0;transition:opacity 1.2s, margin 0.8s ease-out}

.section_comm.active .inner_section{margin:0 auto;opacity:1}

/* 모바일 */
@media all and (max-width:1199px){
}
</style>