<template>
  <PageWithLayout
    ref="wrapFirfin"
    :isTitle="true"
    :isTitleOpacity="true"
    :isTitleOpacityActive="isScrolled"
    :isCloseBtn="true"
    :bottomPadding="120"
    @onClickClose="viewModel.onClickClose()">
    <SectionTop
      :isActive="true"/>
    <SectionIntro
      :isActive="true"/>
    <SectionSafe
      v-if="sectionsList.safe.show"
      :isActive="true"/>
    <SectionBenefit
      v-if="sectionsList.benefit.show"
      :isActive="true"/>
    <SectionPractice
      v-if="sectionsList.practice.show"
      :isActive="true"/>
    <SectionMotivate
      v-if="sectionsList.motivate.show"
      :isActive="true"
      :scrollTop="scrollTop"/>
    <SectionOutro
      v-if="sectionsList.outro.show"
      :isActive="true"/>
    <div class="foot_lemontree foot_lemontree_fiexed">
      <div class="inner_foot">
        <div class="group_btn">
          <Button
            btnStyle="secondary"
            text="자녀 퍼핀카드 신청하기"
            @onClickBtn="sendInterface('registerForCard')" />
        </div>
      </div>
    </div>
    <template v-slot:popup>
      <Layer
        title="아직 고민되시나요?"
        v-if="viewModel.isCardRegisterClosePopup"
        @onClickClose="viewModel.onClickCardRegisterClosePopupClose()">
        <ul class="list_drop">
          <li>
            <strong class="tit_drop">1. 퍼핀카드는 신용카드가 아니에요</strong>
            <p class="desc_drop">선불충전카드여서 <span class="tc_primary">발급과 해지가 간편</span>하고 교통카드로만 쓸 수도 있어요.</p>
          </li>
          <li>
            <strong class="tit_drop">2. 퍼핀의 금융시스템은 안전해요</strong>
            <p class="desc_drop">금융위원회에 등록된 전자금융업자로서, <span class="tc_primary">안전한 자금관리와 보안</span>을 최우선으로 해요.</p>
          </li>
          <li>
            <strong class="tit_drop">3. 이용자의 만족 후기가 많아요</strong>
            <p class="desc_drop">네이버에서 <span class="tc_primary">'퍼핀카드'</span>를 검색해 보세요.</p>
          </li>
        </ul>
        <Button
          btnStyle="secondary"
          text="자녀 퍼핀카드 신청하기"
          @onClickBtn="sendInterface('registerForCard')" />
        <Button
          btnStyle="onlytext"
          btnSize="fit"
          text="다음에 신청하기"
          @onClickBtn="sendInterface('webEnd')" />
      </Layer>
    </template>
  </PageWithLayout>
</template>

<script>
import PageWithLayout from '@/components/layout/PageWithLayout';
// components
import SectionTop from '@/views/card/view/components/register/top/SectionTop.vue';
import SectionIntro from '@/views/card/view/components/register/intro/SectionIntro.vue';
import SectionSafe from '@/views/card/view/components/register/safe/SectionSafe.vue';
import SectionBenefit from '@/views/card/view/components/register/benefit/SectionBenefit.vue';
import SectionPractice from '@/views/card/view/components/register/practice/SectionPractice.vue';
import SectionMotivate from '@/views/card/view/components/register/motivate/SectionMotivate.vue';
import SectionOutro from '@/views/card/view/components/register/outro/SectionOutro.vue';

import Button from '@/components/common/button/Button';

import Layer from '@/components/layout/popup/Layer.vue';
// viewModel
import CardRegisterViewModel from '@/views/card/viewModel/CardRegisterViewModel';
// mixins
import scrolled from '@/mixins/Scrolled';
import InterFaceMixin from '@/mixins/InterFaceMixin';
import BackKeyEventMixin from '@/mixins/BackKeyEventMixin'

export default {
  name: 'CardRegister',
  mixins:[ scrolled, InterFaceMixin, BackKeyEventMixin ],
  components:{
    PageWithLayout,
    
    SectionTop,
    SectionIntro,
    SectionSafe,
    SectionBenefit,
    SectionPractice,
    SectionMotivate,
    SectionOutro,

    Button,

    Layer,
  },
  data(){
    return{
      viewModel : new CardRegisterViewModel(),
      sectionsList:{
        safe: { id:'safe', index:1, show:false },
        benefit: { id:'benefit', index:2, show:false },
        practice: { id:'practice', index:3, show:false },
        motivate: { id:'motivate', index:4, show:false },
        outro: { id:'outro', index:5, show:false },
      },
      nextSection: 'safe'
    }
  },
  watch:{
    scrollTop(){
      const scrollHeight = this.$refs.wrapFirfin.$el.offsetHeight - this.bodyHeight;
      if(this.scrollTop >= scrollHeight - 10){
        const nextSectionItem = this.sectionsList[this.nextSection];
        if(!nextSectionItem) return;
        nextSectionItem.show = true;
        const nextItemFind = Object.values(this.sectionsList).find(item => item.index === nextSectionItem.index +1);
        if(nextItemFind){
          this.nextSection = nextItemFind.id;
        }
      }
    }
  },
  methods:{
    onClickBackKey(){
      if(this.viewModel.isCardRegisterClosePopup){
        this.sendInterface('webEnd');
      }else{
        this.viewModel.isCardRegisterClosePopup = true;
      }
    },
  }
}
</script>

<style scoped>
/* foot */
/* .foot_lemontree{height:120px} */
.foot_lemontree .inner_foot{padding:16px 20px 32px}

/* common */
.section_comm{padding:32px 0;word-wrap:break-word;word-break:keep-all}

.section_comm >>> .tit_section{font-weight:700;font-size:24px;line-height:36px;color:#111;text-align:center}
.section_comm >>> .desc_section{margin-top:8px;padding:0 20px;font-weight:500;font-size:16px;line-height:24px;color:#111;text-align:center}


.section_comm >>> ol.list_section li{font-size:16px;line-height:24px;color:#111}
.section_comm >>> ol.list_section li .txt_num{display:inline-block;width:24px;height:24px;margin-right:8px;padding:4px 0;border-radius:100%;font-family:'Poppins';font-weight:600;font-size:12px;line-height:16px;background-color:#BEA9ED;color:#232323;vertical-align:top;text-align:center;box-sizing:border-box}
.section_comm >>> ol.list_section .tit_item{display:inline-block;font-weight:700;vertical-align:top}
.section_comm >>> ol.list_section .desc_item{margin-top:8px;font-weight:500}
.section_comm >>> ol.list_section li + li{margin-top:24px;padding-top:24px;border-top:1px solid #E7E5E5}

.layer_comm .list_drop li + li{margin-top:16px}
.layer_comm .list_drop + button{margin-top:24px}
.layer_comm .btn_onlytext{margin-top:34px}
</style>